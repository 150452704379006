var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-4" },
    [
      _c("vx-tour", {
        attrs: {
          name: "questionnaireResumeIntroSteps",
          auto: false,
          delegate: _vm.tourStart,
        },
      }),
      _c("vx-tour", {
        ref: "questionnaireResumeExplanationSteps",
        attrs: { name: "questionnaireResumeExplanationSteps", auto: false },
      }),
      _c(
        "div",
        {
          staticClass: "flex items-start sm:items-center justify-between mb-4",
        },
        [
          _c("div", { staticClass: "w-full flex-grow flex gap-4 t" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip.top",
                    value: {
                      content: "Atualizar",
                      delay: { show: 1000, hide: 100 },
                    },
                    expression:
                      "{\n            content: 'Atualizar',\n            delay: { show: 1000, hide: 100 },\n        }",
                    modifiers: { top: true },
                  },
                ],
              },
              [
                _c(
                  "vs-button",
                  {
                    staticClass: "px-2 py-2",
                    attrs: {
                      "text-color": "var(--content-color)",
                      type: "flat",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.getResume(true)
                      },
                    },
                  },
                  [
                    _c("font-awesome-icon", { attrs: { icon: "sync-alt" } }),
                    _c("div", { staticClass: "inline pl-1 font-semibold" }, [
                      _vm._v(_vm._s(_vm.$t("atualizar"))),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip.top",
                    value: {
                      content:
                        "Realiza o calculo da media, mediana e estatísticas de duração novamente.",
                      delay: { show: 1000, hide: 100 },
                    },
                    expression:
                      "{\n          content: 'Realiza o calculo da media, mediana e estatísticas de duração novamente.',\n          delay: { show: 1000, hide: 100 },\n        }",
                    modifiers: { top: true },
                  },
                ],
              },
              [
                _c(
                  "vs-button",
                  {
                    staticClass: "px-2 py-2",
                    attrs: {
                      "text-color": "var(--content-color)",
                      type: "flat",
                    },
                    on: { click: _vm.calcScores },
                  },
                  [
                    _c("font-awesome-icon", { attrs: { icon: "cogs" } }),
                    _c("div", { staticClass: "inline pl-1 font-semibold" }, [
                      _vm._v(_vm._s(_vm.$t("recalcular-dados"))),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "flex flex-col sm:flex-row gap-2" }, [
            _c("b", [_vm._v(_vm._s(_vm.$t("agrupado-por")))]),
            _c(
              "div",
              { staticClass: "flex flex-row gap-2" },
              [
                _c("label", [_vm._v(_vm._s(_vm.$t("individual")))]),
                _c("vs-switch", {
                  model: {
                    value: _vm.isGeneralResume,
                    callback: function ($$v) {
                      _vm.isGeneralResume = $$v
                    },
                    expression: "isGeneralResume",
                  },
                }),
                _c("label", [_vm._v(_vm._s(_vm.$t("general")))]),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm.isGeneralResume
        ? _c(
            "div",
            [
              _vm.generalResumeList.length === 0
                ? _c(
                    "vs-row",
                    { staticClass: "flex text-center mt-4" },
                    [
                      _c("vs-col", { staticClass: "w-full" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("ainda-nao-ha-resumo-geral-disponivel-sel")
                            ) +
                            " "
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._l(_vm.generalResumeList, function (resume) {
                    return _c(
                      "vx-card",
                      {
                        key: resume,
                        staticClass: "mb-4",
                        attrs: { title: _vm.Title },
                      },
                      [
                        _c("div", {
                          directives: [
                            {
                              name: "html-safe",
                              rawName: "v-html-safe",
                              value:
                                resume.answerdQuestion.contentQuestion.data
                                  .title,
                              expression:
                                "resume.answerdQuestion.contentQuestion.data.title",
                            },
                          ],
                          staticClass:
                            "border-bottom editor-content ck-content",
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "mt-6 grid grid-cols-1 sm:grid-cols-4",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex justify-items-center w-full mb-2 sm:mb-0",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "grid grid-cols-1 w-full justify-items-center",
                                  },
                                  [
                                    _c("div", [
                                      _c("h5", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("total-respostas-enviadas")
                                          )
                                        ),
                                      ]),
                                    ]),
                                    _c("div", [
                                      _vm._v(" " + _vm._s(resume.total) + " "),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex justify-items-center w-full mb-2 sm:mb-0",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "grid grid-cols-1 w-full justify-items-center",
                                  },
                                  [
                                    _c("div", [
                                      _c("h5", [
                                        _vm._v(_vm._s(_vm.$t("corretas"))),
                                      ]),
                                    ]),
                                    _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(resume.correction_total) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex justify-items-center w-full mb-2 sm:mb-0",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "grid grid-cols-1 w-full justify-items-center",
                                  },
                                  [
                                    _c("div", [
                                      _c("h5", [
                                        _vm._v(_vm._s(_vm.$t("incorretas"))),
                                      ]),
                                    ]),
                                    _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(resume.incorrection_total) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex justify-items-center w-full mb-2 sm:mb-0",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "grid grid-cols-1 w-full justify-items-center",
                                  },
                                  [
                                    _c("div", [
                                      _c("h5", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("aguardando-correcao-0")
                                          )
                                        ),
                                      ]),
                                    ]),
                                    _c("div", [
                                      _vm._v(
                                        " " + _vm._s(resume.pending_total) + " "
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "mt-6 grid grid-cols-1 sm:grid-cols-2",
                          },
                          [
                            _c(
                              "div",
                              [
                                _c("e-charts", {
                                  ref: "pie",
                                  refInFor: true,
                                  attrs: {
                                    autoresize: "",
                                    options: _vm.questionPieResumeData(resume),
                                    "auto-resize": "",
                                  },
                                }),
                              ],
                              1
                            ),
                            resume.answerdQuestion.contentQuestion.type ===
                              "multiple_choice" ||
                            resume.answerdQuestion.contentQuestion.type ===
                              "check_box"
                              ? _c(
                                  "div",
                                  { staticClass: "mt-6 sm:mb-0" },
                                  [
                                    _c("e-charts", {
                                      ref: "pie",
                                      refInFor: true,
                                      attrs: {
                                        autoresize: "",
                                        options:
                                          _vm.optionsPieResumeData(resume),
                                        "auto-resize": "",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]
                    )
                  }),
            ],
            2
          )
        : _vm._e(),
      _c(
        "div",
        [
          _c("Grid", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isGeneralResume,
                expression: "!isGeneralResume",
              },
            ],
            ref: "individualResumeGrid",
            attrs: {
              direction: "asc",
              noDataText: _vm.$t("nao-ha-resumos-individuais-disponiveis"),
              service: _vm.questionnaireAnswerService,
              route_name: "individual_resume",
              route_grid_path: `/${_vm.id}/resume/individual`,
              hide_actions: true,
              fetchOnMounted: false,
            },
            on: { "grid-UPDATED": _vm.updateIndividualResumeData },
            scopedSlots: _vm._u([
              {
                key: "filterZone",
                fn: function () {
                  return [
                    _vm.showScoreResume
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "flex items-center justify-between sm:flex-row sm:justify-start gap-2 py-2 md:py-0 sm:my-0 h-full",
                          },
                          [
                            _c(
                              "label",
                              { staticClass: "flex-grow sm:flex-grow-0" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "total-de-respostas-unfinished-concluidas",
                                        [_vm.unfinished ? " Concluídas" : ""]
                                      )
                                    ) +
                                    " "
                                ),
                                _c("span", { staticClass: "font-bold" }, [
                                  _vm._v(_vm._s(_vm.answersDone)),
                                ]),
                              ]
                            ),
                            _vm.unfinished
                              ? _c(
                                  "label",
                                  {
                                    staticClass:
                                      "flex-grow sm:flex-grow-0 sm:pl-2 border-grey sm:border-solid border-l border-t-0 border-r-0 border-b-0",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("nao-finalizados")) +
                                        " "
                                    ),
                                    _c("span", { staticClass: "font-bold" }, [
                                      _vm._v(_vm._s(_vm.answersProgress)),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _vm.hasAverage
                              ? _c(
                                  "label",
                                  {
                                    staticClass:
                                      "flex-grow sm:flex-grow-0 sm:pl-2 border-grey sm:border-solid border-l border-t-0 border-r-0 border-b-0",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("media-das-notas")) +
                                        " "
                                    ),
                                    _c("span", { staticClass: "font-bold" }, [
                                      _vm._v(
                                        _vm._s(_vm.formatFloat(_vm.average))
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _vm.hasMedian
                              ? _c(
                                  "label",
                                  {
                                    staticClass:
                                      "flex-grow sm:flex-grow-0 sm:pl-2 border-grey sm:border-solid border-l border-t-0 border-r-0 border-b-0",
                                  },
                                  [
                                    _vm._v(" Mediana: "),
                                    _c("span", { staticClass: "font-bold" }, [
                                      _vm._v(
                                        _vm._s(_vm.formatFloat(_vm.median))
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        )
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
              {
                key: "gridThead",
                fn: function () {
                  return [
                    _c(
                      "vs-th",
                      {
                        key: "name",
                        attrs: {
                          "sort-key": "user.name",
                          width: _vm.$utils.reactive.ratios("35%", "20%"),
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("participant")) + " ")]
                    ),
                    _c(
                      "vs-th",
                      {
                        key: "email",
                        attrs: {
                          "sort-key": "user.email",
                          width: _vm.$utils.reactive.ratios("35%", "25%"),
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("email")) + " ")]
                    ),
                    _c(
                      "vs-th",
                      {
                        key: "inProgress",
                        attrs: {
                          "sort-key": "finished_at",
                          width: _vm.$utils.reactive.ratios("15%", "25%"),
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("progresso")) + " ")]
                    ),
                    _c(
                      "vs-th",
                      {
                        key: "score",
                        attrs: {
                          "sort-key": "score",
                          width: _vm.$utils.reactive.ratios("15%", "30%"),
                        },
                      },
                      [
                        _c("label", [_vm._v(_vm._s(_vm.$t("nota")))]),
                        _vm._v(" "),
                        _vm.totalScoreHeader
                          ? _c("label", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("formatfloat-totalscoreheader", [
                                    _vm.formatFloat(_vm.totalScoreHeader),
                                  ])
                                )
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "gridData",
                fn: function (props) {
                  return _vm._l(props.data, function (tr, i) {
                    return _c(
                      "vs-tr",
                      { key: props.data[i] },
                      [
                        _c("vs-td", { key: "users.name" }, [
                          _vm._v(" " + _vm._s(tr.name) + " "),
                        ]),
                        _c("vs-td", { key: "users.email" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "px-2 w-32 sm:w-64 md:w-80 lg:w-full truncate",
                            },
                            [_vm._v(" " + _vm._s(tr.email) + " ")]
                          ),
                        ]),
                        _c(
                          "vs-td",
                          {
                            key: "inProgress",
                            class: tr.finished_at
                              ? "text-success"
                              : "text-warning",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  tr.finished_at
                                    ? "Concluído"
                                    : "Não finalizado"
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c("vs-td", { key: "score" }, [
                          _c(
                            "div",
                            { staticClass: "pr-4 flex justify-between gap-1" },
                            [
                              _c(
                                "span",
                                { class: _vm.scoreGravity(tr.score) },
                                [_vm._v(_vm._s(_vm.scoreFormat(tr)))]
                              ),
                              _vm.getRowMaxScore(tr)
                                ? _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "parsefloat-getrowmaxscore-tr",
                                            [parseFloat(_vm.getRowMaxScore(tr))]
                                          )
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                        ]),
                      ],
                      1
                    )
                  })
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }